import React from "react";
import { CandidatePanel } from "../index";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./ballot.styles.scss";

function Ballots(props) {
  const orderGuide = [
    "governor",
    "senate",
    "lieutenant_governor",
    "secretary_of_state",
    "attorney_general",
    "labor_commissioner",
    "agriculture_commissioner",
    "insurance_fire_commissioner",
    "state_school_superintendent",
    "us_congress",
    "state_senate",
    "state_house",
  ];
  // const orderHome = ["president", "senate", "gps_commission"];
  function sortRace(ballot, orderArray) {
    return ballot.sort(function (a, b) {
      let indexA = orderArray.indexOf(a.race_type);
      let indexB = orderArray.indexOf(b.race_type);
      return indexA >= indexB ? 1 : -1;
    });
  }
  let filterPendingBallot = props.ballots && props.ballots.slice();
  let orderedRace = sortRace(filterPendingBallot, orderGuide);
  let ballots = orderedRace;
  let ballotDisplay = [];
  ballots &&
    ballots.map(function (ballot, i) {
      return ballotDisplay.push(
        <CandidatePanel key={i} details={ballot} />,
      );
    });
  const { t } = useTranslation();

  return <Container fluid>{ballotDisplay}</Container>;
}

export default Ballots;
