import React from "react";
import { Image, Container, Row, Col } from "react-bootstrap";
import "./VoterRegPage.style.scss";
import {
  starIconOrange,
  iconDesktop,
  iconLocation,
  iconWarning,
  iconEmail,
} from "../../lib/images";
import { Trans, useTranslation } from "react-i18next";
function QuizLPFirstTime() {
  const { t } = useTranslation();
  return (
    <div className="voter-reg-page">
      <div className="quiz-lp-top-header">
        <Image src={starIconOrange} className="icon-star-left" alt="" />
        <div className="top-header-title">
          <p>{t("qft_registering_to_vote_should_be_easy")}</p>
        </div>
        <Image src={starIconOrange} className="icon-star-right" alt="" />
      </div>
      <Container className="quiz-lp-top-content">
        <Row>
          <Col xs="12" md="12">
            <div className="title">{t("qft_register_online_or_mail")}</div>
            <div className="leader">
              {t("qft_georgia_drivers_license_or_state_id")}
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="ctn-quiz-lp0102-rgtcontent">
          <Col xs="12" md="6" className="col-left">
            <div className="title">
              <Image src={iconDesktop} className="icon-title order-1" />
              <span className="order-0">{t("qft_registering_online")}</span>
            </div>
            <p>{t("qft_if_you_have_a_valid_georgia_drivers_license")}</p>
            <p>{t("qft_the_process_is_easy")}</p>
            <a
              href="https://registertovote.sos.ga.gov/GAOLVR/welcometoga.do#no-back-button"
              target="_blank"
              rel="noreferrer"
            >
              <button type="button" className="btn-register">
                {t("register_online")}
              </button>
            </a>
          </Col>
          <Col xs="12" md="6" className="col-right">
            <div className="title">
              <Image src={iconLocation} className="icon-title order-1" />
              <span className="order-0">{t("qft_registering_in_person")}</span>
            </div>
            <p>
              <Trans i18nKey="qft_you_can_register_to_vote_in_person_or">
                <a
                  href="https://georgialibraries.org/find-a-library/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                </a>
                <a
                  href="https://dds.georgia.gov/locations/customer-service-center"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                </a>
                <a
                  href="https://elections.sos.ga.gov/Elections/countyregistrars.do"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                </a>
              </Trans>
            </p>
          </Col>
          <Col xs="12" className="section-bottom">
            <div className="title">
              {t("qft_registering_by_mail")}{" "}
              <Image src={iconEmail} className="icon-title" />
            </div>
            <p>
              <Trans i18nKey="qft_if_you_dont_have_a_georgia_drivers_license">
                <a
                  href="https://registertovote.sos.ga.gov/GAOLVR/welcometoga.do#no-back-button"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                </a>
                <a
                  href="https://sos.ga.gov/sites/default/files/forms/GA_VR_APP_2019.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                </a>
              </Trans>
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default QuizLPFirstTime;
