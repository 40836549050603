import React from "react";
import {
  Header,
  Footer,
  EmailSignup,
  LineSplit,
  QuizLPVoteByMail,
  OfficeDescription,
  AlertSignup,
} from "../components";
import { useMediaQuery } from "react-responsive";

import "../components/QuizLandingPage/QuizLandingPage.style.scss";
import config from "../lib/config";
import { Helmet } from "react-helmet-async";
import { Trans, useTranslation } from "react-i18next";
function OfficeDescriptionPage() {
  const isMobile = useMediaQuery({ query: "(max-width: 998px)" });
  let imageUrl = [
    window.location.protocol,
    "//",
    window.location.host,
    config.seo.default.image,
  ].join("");
  let ogUrl = window.location.href;
  const { t } = useTranslation();
  return (
    <div className="ctn-quiz-landing03">
      <Helmet>
        <title>{t("seo_default_home_title")}</title>
        <meta
          name="description"
          content={t("seo_default_home_page_description")}
        />
        <meta name="image" content={imageUrl} />
        <meta name="twitter:title" content={t("seo_home_tw_title")} />
        <meta
          name="twitter:description"
          content={t("seo_home_tw_description")}
        />
        <meta name="twitter:image" content={imageUrl} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta itemProp="name" content={t("seo_default_home_title")} />
        <meta
          itemProp="description"
          content={t("seo_default_home_page_description")}
        />
        <meta itemProp="image" content={imageUrl} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={ogUrl} />
        <meta property="og:title" content={t("seo_default_home_page_title")} />
        <meta
          property="og:description"
          content={t("seo_default_home_page_description")}
        />
        <meta property="og:image" content={imageUrl} />
      </Helmet>
      <div className="home-top-email">
        <AlertSignup />
      </div>
      <Header />
      <div id="page-wrap">
        <LineSplit isGreen="true" />
        <div class="quiz-landing-header">
          <div class="quiz-landing-title">
            {t("the_offices")}
            <span class="bg-emphasis">{t("what_they_do")}</span>
          </div>
        </div>
        <LineSplit isGreen="true" />
        <OfficeDescription />
        <LineSplit isGreen="true" />
        <EmailSignup isMidPage="true" />
        <LineSplit isGreen="true" />
        <Footer flat={true} />
      </div>
    </div>
  );
}

export default OfficeDescriptionPage;
