import React from "react";
import {
  Header,
  Footer,
  EmailSignup,
  LineSplit,
  RegisterToVote,
  Quiz,
  AlertSignup,
} from "../components";

import "./views.styles.scss";
import config from "../lib/config";
import { Helmet } from "react-helmet-async";
import { Trans, useTranslation } from "react-i18next";
function LearnMore() {
  let imageUrl = [
    window.location.protocol,
    "//",
    window.location.host,
    config.seo.default.image,
  ].join("");
  let ogUrl = window.location.href;
  const { t } = useTranslation();
  return (
    <div>
      <Helmet>
        <title>{t("seo_learn_more_title")}</title>
        <meta
          name="description"
          content={t("seo_learn_more_page_description")}
        />
        <meta name="image" content={imageUrl} />
        <meta name="twitter:title" content={t("seo_learn_more_tw_title")} />
        <meta
          name="twitter:description"
          content={t("seo_learn_more_tw_description")}
        />
        <meta name="twitter:image" content={imageUrl} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta itemProp="name" content={t("seo_learn_more_title")} />
        <meta
          itemProp="description"
          content={t("seo_learn_more_page_description")}
        />
        <meta itemProp="image" content={imageUrl} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={ogUrl} />
        <meta property="og:title" content={t("seo_learn_more_page_title")} />
        <meta
          property="og:description"
          content={t("seo_learn_more_page_description")}
        />
        <meta property="og:image" content={imageUrl} />
      </Helmet>
      <div className="home-top-email">
        <AlertSignup />
      </div>
      <Header />
      <div id="page-wrap" className="ctn-learn-more">
        <LineSplit isGreen="true" />
        <div className="home-title">
          {/* Learn more about the different ways you can <span className="bg-emphasis">make your voice heard this election</span> */}
          <Trans i18nKey="learn_more_about">
            <span className="bg-emphasis"></span>
          </Trans>
        </div>
        <LineSplit isGreen="true" />
        <Quiz />
        <RegisterToVote />
        <LineSplit isGreen="true" />
        <EmailSignup isMidPage="true" />
        <LineSplit isGreen="true" />
        <Footer flat={true} />
      </div>
    </div>
  );
}

export default LearnMore;
