import React, { useEffect, useState } from "react";
import Candidate from "./Candidate.component.jsx";
import Dropdown from "react-dropdown";
import { Tab, Container, Col, Row, Nav, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import config from "../../lib/config";
import "./candidatePanel.styles.scss";
import { iconDropdown } from "../../lib/images";
import { useTranslation } from "react-i18next";

function CandidatePanel(props) {
  let isRecommendation = config.featureReccommendation;
  const [key, setKey] = useState("bio");
  let { t, Trans, i18n } = useTranslation();
  let lang = i18n.language;
  let details = props.details;
  let candidates = details && details.candidates;
  const tabsMap = config.tabs;
  const tabs = Object.keys(tabsMap);
  let availableTabs = [];
  let tabsDisplay = [];
  let tabsLinkDisplay = [];
  let recommendedCandidate,
    disableBio,
    disableIssues,
    disableEndorsements,
    isUncontested,
    recommendedText;
  recommendedCandidate = candidates.find((e) => e.recommended);
  recommendedText =
    (recommendedCandidate &&
      recommendedCandidate.name &&
      "VOTE " + recommendedCandidate.name.toUpperCase()) ||
    "NONE";
  let candidateTab = recommendedCandidate || candidates[0];
  if (candidates && candidates.length > 0) {
    tabs.map(function (tab, i) {
      if (candidateTab[tab] && candidateTab[tab].trim !== "") {
        tabsDisplay.push({ value: tab, label: t(tab) });
        tabsLinkDisplay.push(
          <Nav.Item
            className={
              key === tab ? "issues-tab-active issues-tab" : "issues-tab"
            }
            key={i}
          >
            <Nav.Link eventKey={tab} title={tab.toUpperCase()}>
              {tabsMap[tab]}
            </Nav.Link>
          </Nav.Item>,
        );
        availableTabs.push({ value: tab, label: t(tab) });
      }
      return tabsDisplay;
    });
  }
  // let sortedList = candidates && candidates.sort((x, y) => { return (x.recommended ? -1 : 1) });
  // let candidatesDisplay = props.isRacePage ? sortedList : (sortedList && sortedList.slice(0, 3));
  let candidatesDisplay = candidates;
  if (candidateTab["bio"] === "") {
    disableBio = true;
  }
  // if (candidateTab["endorsements"] === "") { disableEndorsements = true; };
  let isMinimal = false;
  if (tabsDisplay.length < 1) {
    disableIssues = true;
  }
  isMinimal = candidatesDisplay.every(item => item.bio.length==0 && item.endorsements.length==0 && item.issues.length==0);
  if (candidates && candidates.length === 1) isUncontested = true;
  useEffect(() => {
    setKey("bio");
  }, [lang]);
  let raceTitle =
    details && (details.race_name_localized || details.race_name_display);
  return (
    <div>
      <Container className="ballot-container" fluid>
        <Container>
          <Row className="title-background">
            <Container
              className={
                !isMinimal ? "issue-nav-conatiner" : "issue-nav-conatiner-min"
              }
            >
              <div className="title">{raceTitle}</div>
              {isRecommendation ? (
                isUncontested ? (
                  <h4 className="reccommendation-text">UNCONTESTED RACE</h4>
                ) : (
                  <h4 className="reccommendation-text">
                    OUR RECOMMENDATION: <span>{recommendedText}</span>
                  </h4>
                )
              ) : null}
              {!isMinimal ? (
                <Tab.Container
                  id="controlled-tab"
                  activeKey={key}
                  onSelect={(k) => setKey(k)}
                  style={{ display: "inline-flex" }}
                  transition="fade"
                >
                  <Row className="tab-manager">
                    <Nav.Item
                      className="main-tab"
                      style={{ display: disableBio ? "none" : "block" }}
                    >
                      <Nav.Link
                        eventKey="bio"
                        title="Biography"
                        className={key === "bio" ? "active-tab" : ""}
                      >
                        {t("biography")}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item
                      className="main-tab"
                      style={{ display: disableBio ? "none" : "block" }}
                    >
                      <Nav.Link
                        eventKey="experience"
                        title="Experiece"
                        className={key === "experience" ? "active-tab" : ""}
                      >
                        {t("experience")}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item
                      className="main-tab"
                      style={{ display: disableIssues ? "none" : "block" }}
                    >
                      <Nav.Link
                        eventKey={
                          availableTabs.length > 0
                            ? availableTabs[0].value
                            : "bio"
                        }
                        title="On The Issues"
                        className={
                          key !== "bio" &&
                          key !== "endorsements" &&
                          key !== "experience"
                            ? "active-tab"
                            : ""
                        }
                        disabled={tabsDisplay.length <= 0}
                      >
                        {t("issues")}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item
                      className="main-tab"
                      style={{
                        display: disableEndorsements ? "none" : "block",
                      }}
                    >
                      <Nav.Link
                        eventKey="endorsements"
                        title="Endorsements"
                        className={key === "endorsements" ? "active-tab" : ""}
                      >
                        {t("endorsements")}
                      </Nav.Link>
                    </Nav.Item>
                  </Row>
                  {key !== "bio" &&
                  key !== "endorsements" &&
                  key !== "experience" ? (
                    <Row
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "10px auto",
                        width: "100%",
                      }}
                    >
                      <span className="issue-tab-text">
                        {tabsLinkDisplay.length > 0
                          ? t("on_the_issues") + " :"
                          : null}
                      </span>
                      {tabsDisplay.length > 0 ? (
                        <Dropdown
                          options={tabsDisplay}
                          value={key}
                          onChange={(e) => setKey(e.value)}
                          className="issues-dropdown"
                          arrowOpen={
                            <Image
                              src={iconDropdown}
                              alt=""
                              className="icon-drop-down open"
                            />
                          }
                          arrowClosed={
                            <Image
                              src={iconDropdown}
                              alt=""
                              className="icon-drop-down"
                            />
                          }
                        />
                      ) : null}
                    </Row>
                  ) : null}
                </Tab.Container>
              ) : (
                ""
              )}
            </Container>
          </Row>
        </Container>
        {candidatesDisplay.map(function (candidate, i) {
          return (
            <Row key={i} className="zero-margin">
              <Container className="candidate">
                <Candidate
                  details={candidate}
                  racename={details.race_name}
                  isRacePage={props.isRacePage}
                  tab={key}
                  isMinimal={isMinimal}
                />
              </Container>
            </Row>
          );
        })}
      </Container>
    </div>
  );
}

export default CandidatePanel;
