import React, { Component } from 'react';
import { HomePage, Error404, Error500,LearnMore,QuizLanding01,QuizLanding02,QuizLanding03,QuizLanding04,QuizLanding05,QuizLanding06, AboutUs, PrivacyPage, BallotPage, RacePage,OfficeDescriptionPage,CountyInformationPage} from "./views"
import {ScrollToTop} from "./components"
import { GlobalProvider } from './context/GlobalState';

import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import config from "./lib/config";
import Session from './lib/session';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import i18n from "i18next";
import './styles/App.css';

class App extends Component {
    constructor(props) {
        super(props);
        window.session = new Session();
        this.changeLanguage = this.changeLanguage.bind(this);
    }
    componentDidMount() {
        let sourceParam = sessionStorage.getItem(config.akSourceName); // Check for existing session-based source value.
        if (!sourceParam) { // If no session-based source is found, check QS and save to session
            sourceParam=(new URLSearchParams(window.location.search)).get(config.akSourceName);
            if (sourceParam) sessionStorage.setItem(config.akSourceName, sourceParam);
         }
    }

    changeLanguage(lng) {
        i18n.changeLanguage(lng);
    }

    render() {
        let imageUrl = [window.location.protocol, '//', window.location.host, config.seo.default.image].join('');
        let ogUrl = window.location.href;
        let languages = ["en","es","kr"]
        if (window.location.pathname && window.location.pathname.includes("/es")) {
            this.changeLanguage("es")
        }
        if (window.location.pathname && window.location.pathname.includes("/kr")) {
            this.changeLanguage("kr")
        }
        let langParam=(new URLSearchParams(window.location.search)).get("lang");
        if (langParam && (languages.includes(langParam))) {
            this.changeLanguage(langParam)
        }

        const helmetContext = {};
        const targetedRace = Object.keys(config.targetedRace);
        return (
          <HelmetProvider context={helmetContext}>
            <GlobalProvider>
              <Router>
                <div>
                  <Helmet>
                    <title>{config.seo.default.title}</title>
                    <meta
                      name="description"
                      content={config.seo.default.description}
                    />
                    <meta name="image" content={imageUrl} />
                    <meta
                      name="twitter:title"
                      content={config.seo.default.tw_title}
                    />
                    <meta
                      name="twitter:description"
                      content={config.seo.default.tw_description}
                    />
                    <meta name="twitter:image" content={imageUrl} />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta itemProp="name" content={config.seo.default.title} />
                    <meta
                      itemProp="description"
                      content={config.seo.default.description}
                    />
                    <meta itemProp="image" content={imageUrl} />
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content={ogUrl} />
                    <meta
                      property="og:title"
                      content={config.seo.default.tw_title}
                    />
                    <meta
                      property="og:description"
                      content={config.seo.default.page_description}
                    />
                    <meta property="og:image" content={imageUrl} />
                  </Helmet>
                  <ScrollToTop />
                  {/* <PopUp/> */}
                  <Switch>
                    <Route exact path="/check-voter-registration-status">
                      <HomePage />
                    </Route>
                    <Route exact path="/en">
                      <HomePage />
                    </Route>
                    <Route exact path="/es">
                      <HomePage />
                    </Route>
                    <Route exact path="/kr">
                      <HomePage />
                    </Route>
                    <Route
                      exact
                      path={[
                        "/learn-more",
                        "/learn-more-lpq",
                        "/learn-more-lpqs",
                      ]}
                    >
                      <LearnMore />
                    </Route>
                    <Route
                      exact
                      path={["/register-to-vote", "/register-to-vote-lpq"]}
                    >
                      <QuizLanding01 />{" "}
                      {/*Quiz Landing Page - Register to vote (First time Georgia voter) */}
                    </Route>
                    <Route exact path="/returning-register-to-vote">
                      <QuizLanding02 />{" "}
                      {/*Quiz Landing Page - Register to vote (Returning Georgia voter) */}
                    </Route>
                    <Route exact path={["/vote-by-mail", "/vote-by-mail-lpqs"]}>
                      <QuizLanding03 />{" "}
                      {/*Quiz Landing Page - Voting by mail */}
                    </Route>
                    <Route
                      exact
                      path={["/vote-in-person", "/vote-in-person-lpqs"]}
                    >
                      <QuizLanding04 />{" "}
                      {/*Quiz Landing Page - Voting in person, on election day */}
                    </Route>
                    <Route
                      exact
                      path={[
                        "/vote-in-person-early",
                        "/vote-in-person-early-lpqs",
                      ]}
                    >
                      <QuizLanding05 />{" "}
                      {/*Quiz Landing Page - Voting in person, early */}
                    </Route>
                    <Route exact path="/voting-with-a-record">
                      <QuizLanding06 />{" "}
                      {/*Quiz Landing Page - Voting with a record */}
                    </Route>
                    <Route exact path="/about-us">
                      <AboutUs />
                    </Route>
                    <Route exact path="/privacy-policy">
                      <PrivacyPage />
                    </Route>
                    <Route exact path="/">
                      <HomePage />
                    </Route>
                    <Route exact path="/office-descriptions">
                      <OfficeDescriptionPage />
                    </Route>
                    <Route exact path="/county-information">
                      <CountyInformationPage />
                    </Route>
                    {/* <Route exact path="/quiz-landing-06">
                            <QuizLanding06 />; //Quiz Landing Page - Voting in person, on election day 02
                        </Route> */}
                    <Route exact path="/race">
                      <RacePage />
                    </Route>
                    {/* <Route exact path="/voting-guide">
                            <QuizPage />
                        </Route>
                        <Route exact path="/by-mail">
                            <InformationHub viewSection="BYMAIL" />
                        </Route>
                        <Route exact path="/early-in-person">
                            <InformationHub  viewSection="EARLY"/>
                        </Route> */}
                    {/* <Route exact path="/election-day">
                            <InformationHub  viewSection="ELECTION"/>
                        </Route>
                        <Route exact path="/voting-resources">
                            <InformationHub/>
                        </Route>
                        <Route exact path="/voter-information">
                            <InformationHub alerts/>
                        </Route> */}
                    <Route path="/ballot/:municipal/:schoolDistrict?">
                      <BallotPage />
                    </Route>
                    {/* <Route path="/ballot/">
                      <BallotPage />
                    </Route> */}
                    {/* {targetedRace.map((race,i) => {
                            return <Route exact path={'/'+race} key={i}>
                                        <RacePage race={race} targetedQuery={config.targetedRace[race]}/>
                                    </Route>
                        })} */}
                    <Route path="/500">
                      <Error500 />
                    </Route>
                    <Route path="/404">
                      <Error404 />
                    </Route>
                    <Route>
                      <Error404 />
                    </Route>
                  </Switch>
                </div>
              </Router>
            </GlobalProvider>
          </HelmetProvider>
        );
    }
}

export default App;
