import React, { useState, useContext, useEffect } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import ReactHtmlParser, { convertNodeToElement } from "react-html-parser";
import { iconChecked, iconCheck ,facebook,twitter,instagram,tiktok,youtube,threads,linkedin,candidateDefault} from "../../lib/images";
import { useMediaQuery } from "react-responsive";
// import config from "../../lib/config";
import { GlobalContext } from "../../context/GlobalState";
import { Trans,useTranslation } from "react-i18next";
import { urlFor } from '../../sanityClient';
import { HashLink } from "react-router-hash-link";
const extractTabText = (details, tab) => {
  if (details[tab] && details[tab].length > 0 && details[tab][0].children && details[tab][0].children.length > 0) {
    return details[tab][0].children[0].text;
  }
  return "";
};

function Candidate(props) {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  let details = props.details;
  let raceName = props.racename;
  let isRacePage = props.isRacePage;
  let isMinimal = props.isMinimal;
  let { t } = useTranslation();
  const { state, addSelection, removeSelection, setStep } =
    useContext(GlobalContext);
  let candidateSelection = state.candidateSelection;
  const [isSelected, setSelected] = useState(false);
  const [isDisabled, setDisabled] = useState(false);
  useEffect(() => {
    let findSelection = candidateSelection.find(
      (selection) => selection.race === raceName,
    );
    if (findSelection) {
      if (findSelection.selection === details.name) {
        setSelected(true);
        setDisabled(false);
      } else {
        setSelected(false);
        setDisabled(true);
      }
    } else {
      setSelected(false);
      setDisabled(false);
    }
  }, [state, candidateSelection, details, raceName]);
  const toggleSelect = (selected) => {
    setSelected(!selected);
    let payload = { race: raceName, selection: details.name };
    if (selected) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "candidate_unselect",
        race: raceName,
        candidate: details.name,
      });
      removeSelection(payload);
    } else {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "candidate_select",
        race: raceName,
        candidate: details.name,
      });
      addSelection(payload);
      if (state.email) {
        setStep(4);
      }
    }
  };

  let disableSelection = state.step === 1 || isRacePage;
  let tabText = extractTabText(details, props.tab);

  // Use tabText for your showDetails:
  let showDetails = tabText;

  if (props.tab === "experience") {
    // Build experience conditionally based on available data
    if (details.job_title)
      showDetails +=
        `<p><strong>${t("current_job")}</strong> ` + details.job_title + "</p>";
    if (details.personal_education)
      showDetails +=
        `<p><strong>${t("personal_education")}</strong></p>` +
        details.personal_education;
    if (details.experience) showDetails += details.experience;
  }
  let thumbImage = details.image && urlFor(details.image).url();
  if(!details.image) thumbImage = candidateDefault;
  let options = {
    decodeEntities: true,
    transform: function transform(node, index) {
      if (node.type === "tag" && node.name === "a") {
        node.attribs.target = "_blank";
        node.attribs.rel = "noopener";
        let element = convertNodeToElement(node, index, transform);
        if (node.children[0].data === "this website") {
          return <span key={index}>{element}.</span>;
        }
        return element;
      }
    },
  };
  // let isRecommendation = config.featureRecommendation;
  let item = {};
  let mobileCandidate = (
    <Container
      className={`candidate-container candidate-mobile  ${
        isSelected ? "is-selected-candidate" : ""
      }`}
      fluid
    >
      <Container>
        <Row>
          <Col xs={{ span: 12, offset: 0 }}>
            <Row>
              <Col xs="4" style={{paddingRight: "0px"}}>
                <div className="thumb-container">
                  {thumbImage && <Image src={thumbImage} alt={details.name} />}
                </div>
              </Col>
              <Col xs="8">
                <Row>
                  <Col xs="12" lg="6">
                    <Row style={{ padding: "10px 0px" }}>
                      <Col xs="12">
                        <h3>{details.name.toUpperCase()}</h3>
                      </Col>
                      <Col xs="12">
                        {isMinimal ? (
                          <>
                            {details.facebook_url ? (
                              <a target="_blank" href={details.facebook_url}>
                                <Image
                                  src={facebook}
                                  alt=""
                                  className="icon-social"
                                />
                              </a>
                            ) : (
                              ""
                            )}
                            {details.twitter_url ? (
                              <a target="_blank" href={details.twitter_url}>
                                <Image
                                  src={twitter}
                                  alt=""
                                  className="icon-social"
                                />
                              </a>
                            ) : (
                              ""
                            )}
                            {details.instagram_url ? (
                              <a target="_blank" href={details.instagram_url}>
                                <Image
                                  src={instagram}
                                  alt=""
                                  className="icon-social"
                                />
                              </a>
                            ) : (
                              ""
                            )}
                            {details.tiktok_url ? (
                              <a target="_blank" href={details.tiktok_url}>
                                <Image
                                  src={tiktok}
                                  alt=""
                                  className="icon-social"
                                />
                              </a>
                            ) : (
                              ""
                            )}
                            {details.youtube_url ? (
                              <a target="_blank" href={details.youtube_url}>
                                <Image
                                  src={youtube}
                                  alt=""
                                  className="icon-social"
                                />
                              </a>
                            ) : (
                              ""
                            )}
                            {details.threads_url ? (
                              <a target="_blank" href={details.threads_url}>
                                <Image
                                  src={threads}
                                  alt=""
                                  className="icon-social"
                                />
                              </a>
                            ) : (
                              ""
                            )}
                            {details.linkedin_url ? (
                              <a target="_blank" href={details.linkedin_url}>
                                <Image
                                  src={linkedin}
                                  alt=""
                                  className="icon-social"
                                />
                              </a>
                            ) : (
                              ""
                            )}
                          </>
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col xs="12" lg="6">
                    <div
                      className="candidate-select"
                      style={{ display: disableSelection ? "none" : "block" }}
                    >
                      {isDisabled ? (
                        <div
                          className="user-checked is-disabled"
                          onClick={() => toggleSelect(isSelected)}
                        >
                          <div className="user-checked-checkbox">
                            <Image
                              src={iconCheck}
                              alt=""
                              className="icon-user-unchecked"
                            />
                          </div>
                          <div className={"user-checked-title unselect"}>
                            {t("select_candidate")}
                          </div>
                        </div>
                      ) : (
                        <div
                          className="user-checked"
                          onClick={() => toggleSelect(isSelected)}
                        >
                          {isSelected ? (
                            <Image
                              src={iconChecked}
                              alt=""
                              className="icon-user-checked"
                            />
                          ) : null}
                          <div className="user-checked-checkbox">
                            <Image
                              src={iconCheck}
                              alt=""
                              className="icon-user-unchecked"
                            />
                          </div>
                          <div
                            className={
                              isSelected
                                ? "user-checked-title selected"
                                : item.status === 2
                                ? "user-checked-title unselect"
                                : "user-checked-title"
                            }
                          >
                            {isSelected
                              ? "Candidate selected"
                              : "Select Candidate"}
                          </div>
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col xs={{ span: 12, offset: 0 }} style={{ textAlign: "left" }}>
            <div className="content-details">
              {isMinimal ? (
                <>
                  {details.website ? (
                    <p>
                      <Trans i18nKey="to_learn_more">
                        <a
                          target="_blank"
                          href={details.website}
                          className="campaign-website"
                        ></a>
                      </Trans>
                    </p>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ReactHtmlParser(showDetails, options)
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
  let desktopCandidate = (
    <Container
      className={`candidate-container ${
        isSelected ? "is-selected-candidate" : ""
      }`}
      fluid
    >
      <Container>
        <Row>
          <Col
            xs={{ span: 8, offset: 2 }}
            md={{ span: 4, offset: 0 }}
            lg={{ span: 2, offset: 0 }}
          >
            <div className="thumb-container">
              {thumbImage && <Image src={thumbImage} alt={details.name} />}
            </div>
          </Col>
          <Col
            xs={12}
            md={8}
            style={{ textAlign: "left" }}
            lg={{ span: 10, offset: 0 }}
          >
            <Row>
              <Col xs="12" lg="6">
                <Row style={{ padding: "10px 0px" }}>
                  <Col xs="12">
                    <h3>{details.name.toUpperCase()}</h3>
                  </Col>
                  <Col xs="12">
                    <div className="content-details">
                      {isMinimal ? (
                        <>
                          {details.facebook_url ? (
                            <a target="_blank" href={details.facebook_url}>
                              <Image
                                src={facebook}
                                alt=""
                                className="icon-social"
                              />
                            </a>
                          ) : (
                            ""
                          )}
                          {details.twitter_url ? (
                            <a target="_blank" href={details.twitter_url}>
                              <Image
                                src={twitter}
                                alt=""
                                className="icon-social"
                              />
                            </a>
                          ) : (
                            ""
                          )}
                          {details.instagram_url ? (
                            <a target="_blank" href={details.instagram_url}>
                              <Image
                                src={instagram}
                                alt=""
                                className="icon-social"
                              />
                            </a>
                          ) : (
                            ""
                          )}
                          {details.tiktok_url ? (
                            <a target="_blank" href={details.tiktok_url}>
                              <Image
                                src={tiktok}
                                alt=""
                                className="icon-social"
                              />
                            </a>
                          ) : (
                            ""
                          )}
                          {details.youtube_url ? (
                            <a target="_blank" href={details.youtube_url}>
                              <Image
                                src={youtube}
                                alt=""
                                className="icon-social"
                              />
                            </a>
                          ) : (
                            ""
                          )}
                          {details.threads_url ? (
                            <a target="_blank" href={details.threads_url}>
                              <Image
                                src={threads}
                                alt=""
                                className="icon-social"
                              />
                            </a>
                          ) : (
                            ""
                          )}
                          {details.linkedin_url ? (
                            <a target="_blank" href={details.linkedin_url}>
                              <Image
                                src={linkedin}
                                alt=""
                                className="icon-social"
                              />
                            </a>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        ReactHtmlParser(showDetails, options)
                      )}
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xs="12" lg="6">
                <div
                  className="candidate-select"
                  style={{ display: disableSelection ? "none" : "block" }}
                >
                  {isDisabled ? (
                    <div
                      className="user-checked is-disabled"
                      onClick={() => toggleSelect(isSelected)}
                    >
                      <div className="user-checked-checkbox">
                        <Image
                          src={iconCheck}
                          alt=""
                          className="icon-user-unchecked"
                        />
                      </div>
                      <div className={"user-checked-title unselect"}>
                        {t("select_candidate")}
                      </div>
                    </div>
                  ) : (
                    <div
                      className="user-checked"
                      onClick={() => toggleSelect(isSelected)}
                    >
                      {isSelected ? (
                        <Image
                          src={iconChecked}
                          alt=""
                          className="icon-user-checked"
                        />
                      ) : null}
                      <div className="user-checked-checkbox">
                        <Image
                          src={iconCheck}
                          alt=""
                          className="icon-user-unchecked"
                        />
                      </div>
                      <div
                        className={
                          isSelected
                            ? "user-checked-title selected"
                            : item.status === 2
                            ? "user-checked-title unselect"
                            : "user-checked-title"
                        }
                      >
                        {isSelected
                          ? t("candidate_selected")
                          : t("select_candidate")}
                      </div>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
            <div className="content-details">
              <>
                {details.website ? (
                  <p>
                    <Trans i18nKey="to_learn_more">
                      <a
                        target="_blank"
                        href={details.website}
                        className="campaign-website"
                      ></a>
                    </Trans>
                  </p>
                ) : (
                  ""
                )}
              </>
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
  return <div>{isMobile ? mobileCandidate : desktopCandidate}</div>;
}

export default Candidate;
