import React from "react";
import { Image } from "react-bootstrap";
import "./AboutUs.style.scss";
import { SponsorBanner } from "../";
import { iconPlus, iconMinus } from "../../lib/images";
import Collapse from "react-bootstrap/Collapse";
import { useState } from "react";
import { useTranslation } from "react-i18next";
function AboutUs() {
  const [open1, setOpen1] = useState(true);
  const [open2, setOpen2] = useState(false);
  // const [open3, setOpen3] = useState(false);
  const { t } = useTranslation();
  return (
    <div className="ctn-quiz-lp04 ctn-quiz-lp06">
      <div className="collape-item" id="partners">
        <div className="collape-item-top">
          <div className="collape-item-lable">{t("about_us")}</div>
          <button
            type="button"
            className="collape-item-button"
            onClick={() => setOpen1(!open1)}
            aria-controls="collapse-text-01"
            aria-expanded={open1}
          >
            {open1 ? (
              <Image src={iconMinus} className="icon-minus" />
            ) : (
              <Image src={iconPlus} />
            )}
          </button>
        </div>
        <Collapse in={open1}>
          <div id="collapse-text-01" className="collapse-text-content">
            <div className="collapse-text-item">
              <div className="col-left">
                <SponsorBanner />
              </div>
              <div className="col-right">
                <div className="text-content">
                  {t("about_us_is_product_offered")}
                </div>
                <div className="text-content">{t("about_us_that_why")}</div>
              </div>
            </div>
          </div>
        </Collapse>
      </div>
      <div className="collape-item" id="contact">
        <div className="collape-item-top">
          <div className="collape-item-lable">{t("contact_us")}</div>
          <button
            type="button"
            className="collape-item-button"
            onClick={() => setOpen2(!open2)}
            aria-controls="collapse-text-01"
            aria-expanded={open2}
          >
            {open2 ? (
              <Image src={iconMinus} className="icon-minus" />
            ) : (
              <Image src={iconPlus} />
            )}
          </button>
        </div>
        <Collapse in={open2}>
          <div id="collapse-text-02" className="collapse-text-content">
            <div className="collapse-text-item">
              <div className="col-left">
                <div className="text-content">
                  Dialogue Collaborative is a project of Georgia Alliance
                  Education Fund, a nonpartisan, nonprofit organization
                  dedicated to providing voters with the information they need
                  to vote and cast a well-informed ballot. All candidate
                  information has been drawn from publicly available sources
                  without candidate involvement. If you believe any information
                  has been presented in error,{" "}
                  <a href="mailto:candidates@thegeorgiavoterguide.com">
                    please email us
                  </a>
                  .
                </div>
              </div>
              <div className="col-right"></div>
            </div>
          </div>
        </Collapse>
      </div>
    </div>
  );
}
export default AboutUs;
