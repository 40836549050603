import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { iconWarning, iconHandOrange, iconQuizLP01 } from "../../lib/images";
import { HashLink } from "react-router-hash-link";
import "./Calendar.style.scss";
import { Trans, useTranslation } from "react-i18next";

function Calendar(props) {
  let isHome = props.isHome;
  const { t } = useTranslation();
  return (
    <div>
      <Container id="calendar" className="calendar-section" fluid>
        <Row noGutters>
          <div className={isHome ? "title peach-color" : "title"}>
            {t("save_these_important")}
          </div>
          <div className="leader">
            {/* And be sure to{" "}
							<HashLink to='/#voter-status' smooth>
								<strong>check your voter registration status</strong>
							</HashLink>{" "}
							to confirm you’re all set to cast your vote this election */}
            <p>
              <Trans i18nKey="check_your_voter">
                <HashLink to="/#voter-status"></HashLink>
              </Trans>
            </p>
          </div>
        </Row>
        <Row>
          <Col xs="12" xl="12" className="col-left">
            <Container className="ctn-text-content-note" fluid>
              <Row className="text-content-note" noGutters>
                <Col className="text-content-note-step">
                  <Trans i18nKey="voter_registration_deadline">
                    <span class="language-customization-es"></span>
                  </Trans>
                </Col>
                <Col className="text-content-note-t">
                  <Trans i18nKey="february_12_5pm">
                    <span class="language-customization-es"></span>
                  </Trans>
                </Col>
              </Row>
              <Row className="text-content-note" noGutters>
                <Col className="text-content-note-step">
                  <Trans i18nKey="early_voting_starts">
                    <span class="language-customization-es"></span>
                  </Trans>
                </Col>
                <Col className="text-content-note-t">
                  <div>
                    <Trans i18nKey="february_19-march_8"></Trans>
                  </div>
                </Col>
              </Row>
              <Row className="text-content-note" noGutters>
                <Col className="text-content-note-step">
                  <Trans i18nKey="pres_primary">
                    <span class="language-customization-es"></span>
                  </Trans>
                </Col>
                <Col className="text-content-note-t">
                  <div>
                    <Trans i18nKey="march_12"></Trans>
                  </div>
                </Col>
              </Row>
              <Row className="text-content-note" noGutters>
                <Col className="text-content-note-step">
                  <Trans i18nKey="vr_deadline_may_general_primary">
                    <span class="language-customization-es"></span>
                  </Trans>
                </Col>
                <Col className="text-content-note-t">
                  <div>
                    <Trans i18nKey="apr_22_1159"></Trans>
                  </div>
                </Col>
              </Row>
              <Row className="text-content-note" noGutters>
                <Col className="text-content-note-step">
                  <Trans i18nKey="gen_primary_early">
                    <span class="language-customization-es"></span>
                  </Trans>
                </Col>
                <Col className="text-content-note-t">
                  <div>
                    <Trans i18nKey="apr_29-may_17"></Trans>
                  </div>
                </Col>
              </Row>
              <Row className="text-content-note" noGutters>
                <Col className="text-content-note-step">
                  <Trans i18nKey="gen_primary">
                    <span class="language-customization-es"></span>
                  </Trans>
                </Col>
                <Col className="text-content-note-t">
                  <div>
                    <Trans i18nKey="may_21"></Trans>
                  </div>
                </Col>
              </Row>
              <Row className="text-content-note" noGutters>
                <Col className="text-content-note-step">
                  <Trans i18nKey="gen_primary_gen_fed_runoff_early">
                    <span class="language-customization-es"></span>
                  </Trans>
                </Col>
                <Col className="text-content-note-t">
                  <div>
                    <Trans i18nKey="no_later_june10-14"></Trans>
                  </div>
                </Col>
              </Row>
              <Row className="text-content-note" noGutters>
                <Col className="text-content-note-step">
                  <Trans i18nKey="gen_primary_gen_fed_runoff">
                    <span class="language-customization-es"></span>
                  </Trans>
                </Col>
                <Col className="text-content-note-t">
                  <div>
                    <Trans i18nKey="june_18"></Trans>
                  </div>
                </Col>
              </Row>
              <Row className="text-content-note" noGutters>
                <Col className="text-content-note-step">
                  <Trans i18nKey="vr_deadline_nov_gen">
                    <span class="language-customization-es"></span>
                  </Trans>
                </Col>
                <Col className="text-content-note-t">
                  <div>
                    <Trans i18nKey="oct_7"></Trans>
                  </div>
                </Col>
              </Row>
              <Row className="text-content-note" noGutters>
                <Col className="text-content-note-step">
                  <Trans i18nKey="gen_early">
                    <span class="language-customization-es"></span>
                  </Trans>
                </Col>
                <Col className="text-content-note-t">
                  <Trans i18nKey="oct_15-nov_1">
                    <span class="language-customization-es"></span>
                  </Trans>
                </Col>
              </Row>
              <Row className="text-content-note" noGutters>
                <Col className="text-content-note-step">
                  <Trans i18nKey="general_election">
                    <span class="language-customization-es"></span>
                  </Trans>
                </Col>
                <Col className="text-content-note-t">
                  <Trans i18nKey="nov_5">
                    <span class="language-customization-es"></span>
                  </Trans>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default Calendar;
