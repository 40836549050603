import React, { useState, useEffect } from "react";
import { Container, Image, Row, Col, Form } from "react-bootstrap";
import "./EmailSignup.styles.scss";
import { iconHandRightWhite, closeIconLight } from "../../lib/images";
import { LineSplit } from "../../components";
import * as qs from "qs";
import { useLocation } from "react-router-dom";
import config from "../../lib/config";
import { useTranslation } from "react-i18next";

function EmailSignup({ isMidPage }) {
  const [showAlert, setShowAlert] = useState(true);
  const [status, setStatus] = useState("INITIAL");
  const [email, setEmail] = useState("");
  const [sourceParam, setSourceParam] = useState(
    sessionStorage.getItem(config.akSourceName),
  );
  const country = "United States";
  // const onClickToggleAlert = () => {
  // 	setShowAlert(!showAlert);
  // };
  const location = useLocation();
  useEffect(() => {
    if (!sourceParam)
      setSourceParam(
        qs.parse(location.search, { ignoreQueryPrefix: true })[
          config.akSourceName
        ],
      ); // If no stored source exists, check QS. Use if found.
  }, [location, sourceParam]);
  const toggleAlert = () => {
    setShowAlert(false);
  };
  function handleSubmit(e) {
    e.preventDefault();
    var formData = new URLSearchParams();
    let pageForm = isMidPage ? "gavginfopagefooter" : "gavgalertbanner";
    formData.append("page", pageForm);
    formData.append("email", email);
    if (sourceParam) {
      formData.append("source", sourceParam);
    }
    formData.append("country", country);
    window.session.postSubscribeEmail(formData).then(
      function (res) {
        setStatus("SUCCESS");
      },
      function (e) {
        setStatus("FAILED");
      },
    );
  }
  const { t, i18n } = useTranslation();
  const renderForm = (
    <Container>
      <Row>
        <Col xs="12" md="12" className="email-signup-input">
          <Form
            id="content_min_signup"
            className="action_form"
            name="act"
            onSubmit={(e) => handleSubmit(e)}
            inline
          >
            <div className="ak-grid-row">
              <div
                id="signup-story"
                className="ak-grid-col ak-grid-col-6-of-12"
              >
                <div className="ak-mobile-lead"></div>
              </div>
              <div
                id={isMidPage ? "signup-form-mid" : "signup-form"}
                className="ak-grid-col ak-grid-col-6-of-12 ak-styled-fields ak-labels-overlaid ak-errs-below"
              >
                <ul className="compact" id="ak-errors"></ul>
                <div id="unknown_user" className="ak-user-form">
                  <Form.Group id="ak-fieldbox-email" className="required">
                    <Container fluid>
                      <Row noGutters className="form-email">
                        <Col xs={isMidPage ? "12" : "11"} lg="7">
                          <Form.Label htmlFor="id_email" srOnly>
                            Email address
                            <span className="ak-required-flag">*: </span>
                          </Form.Label>
                          <Form.Label
                            className={
                              "email-signup-title language-customization-" +
                              i18n.language
                            }
                          >
                            {t("sign_up_email")}.
                          </Form.Label>
                        </Col>
                        <Col xs="9" lg="3">
                          <Form.Control
                            type="email"
                            name="email"
                            id="id_email"
                            className="ak-userfield-input"
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder={t("email_address")}
                            required
                          />
                        </Col>
                        <Col xs="3" lg="2">
                          <button type="submit" className="full btn-send-email">
                            <span className="search-candidate-mobile">
                              {t("sign_up")}
                            </span>
                            <Image src={iconHandRightWhite} alt="" />
                          </button>
                        </Col>
                      </Row>
                    </Container>
                  </Form.Group>
                </div>
              </div>
            </div>
          </Form>
        </Col>
      </Row>
      <div
        className={(isMidPage ? "display-none " : "") + "close-email-signup"}
      >
        <Image
          src={closeIconLight}
          className="btn-close-signup"
          alt=""
          onClick={() => toggleAlert()}
        />
      </div>
    </Container>
  );
  const renderStatus = (status) => {
    switch (status) {
      case "INITIAL":
        return renderForm;
      case "SUCCESS":
        return (
          <span className="email-signup-title">
            {t("thank_you_election_updates")}
          </span>
        );
      case "FAILED":
        return (
          <span className="email-signup-title">
            There has been an issue, please try again.
          </span>
        );
      default:
        return renderForm;
    }
  };
  if (showAlert) {
    return (
      <div className="ctn-email-signup">
        {renderStatus(status)}
        {isMidPage ? "" : <LineSplit isGreen="true" isShort="true" />}
      </div>
    );
  }
  return "";
}
export default EmailSignup;
