import React, { useState, useContext, useEffect } from "react";
import { Image, Container, Row, Col } from "react-bootstrap";
import { iconDropdownWhite, iconDone } from "../../lib/images";
import { GlobalContext } from "../../context/GlobalState";
import Collapse from "react-bootstrap/Collapse";
import { HashLink } from "react-router-hash-link";
import "./GoalMeter.style.scss";
import { useTranslation } from "react-i18next";

function GoalMeter() {
  const { t } = useTranslation();
  const { state, openGoalMeter } = useContext(GlobalContext);
  let { step, ballotList, isOpen } = state;
  const [show, setShow] = useState(isOpen);
  let totalCandidates = ballotList ? ballotList.length : 0;
  const itemWith =totalCandidates==1?50: Math.floor(100 / totalCandidates);
  let hasOneRace=false;
  if(totalCandidates===1) {
    hasOneRace=true;
  } else hasOneRace=false;
  let title,
    downloadText = "";
  let totalCandidateSelected =
    state.candidateSelection && state.candidateSelection.length;
  let isComplete =
    totalCandidates > 0 && totalCandidateSelected === totalCandidates;
  let selectingCandidates = !state.email && totalCandidateSelected > 0;
  let emailEntered = state.email;
  let isDownload = false;

  if (step > 1 && totalCandidateSelected === 0) {
    title = t("goal_meter_make_your_ballot");
  }
  if (selectingCandidates) {
    title = t("goal_meter_continue_filling_out");
    downloadText = t("goal_meter_email_guide");
  }
  if (step > 1 && !emailEntered && totalCandidateSelected > 0) {
    title = t("goal_meter_fill_out_your_ballot");
    downloadText = t("goal_meter_email_guide");
  }
  if (emailEntered && !isComplete) {
    title = t("goal_meter_continue_filling_out");
    downloadText = t("goal_meter_download_guide");
    isDownload = true;
  }
  if (emailEntered && isComplete) {
    title = t("goal_meter_youve_made_all");
    downloadText = t("goal_meter_download_guide");
    isDownload = true;
  }
  // if(step === 2) title="Fill out your ballot and email yourself a copy when you've made your choices";
  // if(step > 1 && !isComplete) title="Continue filling out your ballot and download when you've made your choices";
  let disableButton = step > 1 && totalCandidateSelected === 0;
  let candidatesJSON =
    state.candidateSelection &&
    state.candidateSelection.length > 0 &&
    encodeURI(JSON.stringify(state.candidateSelection))
      .replace(/\(/g, "%28")
      .replace(/\)/g, "%29");
  let candidatesQuery = candidatesJSON ? "&selections=" + candidatesJSON : "";
  let linkPdfQuery = state.query + "?pdf=view" + candidatesQuery;
  useEffect(() => {
    setShow(isOpen);
  }, [isOpen]);
  return (
    <div>
      {step > 1 && totalCandidates ? (
        <Collapse in={show}>
          <Container className="ctn-goal-meter" fluid id="goal-meter">
            <Container
              style={{ display: show ? "block" : "none", maxWidth: "1440px" }}
              fluid
            >
              <Row className="ctn-goal-meter-content" noGutters>
                <Col xs="12" md="12" lg="5" className="goal-meter-details">
                  <h4>{title}</h4>
                  {!disableButton ? (
                    <HashLink
                      smooth
                      to={isDownload ? linkPdfQuery : "#personalize"}
                      target={isDownload ? "_blank" : ""}
                      className={`goal-meter-button ${
                        isComplete ? "download-guide" : ""
                      }`}
                    >
                      {downloadText}
                    </HashLink>
                  ) : null}
                  {!disableButton &&
                  step > 1 &&
                  totalCandidateSelected >= 1 &&
                  !isDownload ? (
                    <HashLink
                      smooth
                      to={isDownload ? linkPdfQuery : "#personalize"}
                      target={isDownload ? "_blank" : ""}
                      className={`goal-meter-button ${
                        isComplete ? "text-guide text-complete" : "text-guide"
                      }`}
                    >
                      {t("text_guide")}
                    </HashLink>
                  ) : null}
                </Col>
                <Col xs="7" md="10" lg="6" className="goal-meter-progress-bar">
                  <div
                    className={
                      hasOneRace == true
                        ? "goal-meter-progress-step one-race"
                        : "goal-meter-progress-step"
                    }
                  >
                    {isComplete ? (
                      <div className="check-done">
                        <Image src={iconDone} alt="" className="icon-check" />
                      </div>
                    ) : (
                      ""
                    )}

                    <div
                      className={
                        totalCandidateSelected >= 1
                          ? "first-step item-step active"
                          : "first-step item-step"
                      }
                      style={{ width: itemWith + "%" }}
                    ></div>
                    {totalCandidates > 2
                      ? ballotList.map((item, idx) =>
                          idx > 1 ? (
                            <div
                              key={idx}
                              className={
                                totalCandidateSelected >= idx
                                  ? "item-step next-step active"
                                  : "item-step next-step"
                              }
                              style={{ width: itemWith + "%" }}
                            ></div>
                          ) : (
                            ""
                          )
                        )
                      : ""}
                    <div
                      className={
                        totalCandidateSelected >= totalCandidates
                          ? "last-step item-step active"
                          : "last-step item-step"
                      }
                      style={{ width: itemWith + "%" }}
                    ></div>
                  </div>
                </Col>
                <Image
                  src={iconDropdownWhite}
                  alt=""
                  className={
                    totalCandidateSelected >= 1
                      ? "icon-drop-down-white move-icon"
                      : "icon-drop-down-white"
                  }
                  onClick={() => openGoalMeter(!isOpen)}
                  aria-controls="goal-meter"
                />
              </Row>
            </Container>
            <Container
              className="collapsed-goal-meter"
              style={{ display: !show ? "block" : "none", maxWidth: "1440px" }}
              fluid
            >
              <Row className="ctn-goal-meter-content">
                <Col xs="11" className="goal-meter-details">
                  <h4>{t("goal_meter_see_your_selection_progress")} </h4>
                </Col>
                <Image
                  src={iconDropdownWhite}
                  alt=""
                  className="icon-drop-down-white"
                  onClick={() => {
                    openGoalMeter(!isOpen);
                  }}
                  aria-controls="goal-meter"
                />
              </Row>
            </Container>
          </Container>
        </Collapse>
      ) : (
        ""
      )}
    </div>
  );
}
export default GoalMeter;
