import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import {
  sponsor1,
  sponsor2,
  sponsor3,
  sponsor4,
  sponsor5,
  sponsor6,
  sponsor7,
  sponsor8,
  sponsor9,
  sponsor10,
  sponsor11,
  sponsor12,
  sponsor13,
  sponsor14,
  sponsor15,
  sponsor16,
  sponsor17,
  sponsor18,
  sponsor19,
  sponsor20,
  sponsor21,
  sponsor22,
  sponsor23,
  sponsor24,
  sponsor25,
  sponsor26,
  sponsor27,
  sponsor28,
  sponsor29,
  sponsor30,
  sponsor31,
  sponsor32,
  sponsor33,
  sponsor34,
  sponsor35,
} from "../../lib/images";
import "./sponsorBanner.styles.scss";

function SponsorBanner() {
  return (
    <div>
      <div id="about-us">
        <Container className="sponsor-banner">
          <Row noGutters>
            <Col xs="12">
              <Container className="sponsor-container">
                <Row
                  noGutters
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Col xs={3} md={3} lg={3} xl={true}>
                    <a
                      className={sponsor1.link ? "" : "disable-link"}
                      href={sponsor1.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor1.src} alt={sponsor1.altTxt} />
                    </a>
                  </Col>
                  <Col xs={3} md={3} lg={3} xl={true}>
                    <a
                      className={sponsor2.link ? "" : "disable-link"}
                      href={sponsor2.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor2.src} alt={sponsor2.altTxt} />
                    </a>
                  </Col>
                  <Col xs={3} md={3} lg={3} xl={true}>
                    <a
                      className={sponsor3.link ? "" : "disable-link"}
                      href={sponsor3.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor3.src} alt={sponsor3.altTxt} />
                    </a>
                  </Col>
                  <Col xs={3} md={3} lg={3} xl={true}>
                    <a
                      className={sponsor4.link ? "" : "disable-link"}
                      href={sponsor4.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor4.src} alt={sponsor4.altTxt} />
                    </a>
                  </Col>
                  <Col xs={3} md={3} lg={3} xl={true}>
                    <a
                      className={sponsor5.link ? "" : "disable-link"}
                      href={sponsor5.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor5.src} alt={sponsor5.altTxt} />
                    </a>
                  </Col>
                  <Col xs={3} md={3} lg={3} xl={true}>
                    <a
                      className={sponsor6.link ? "" : "disable-link"}
                      href={sponsor6.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor6.src} alt={sponsor6.altTxt} />
                    </a>
                  </Col>
                  <Col xs={3} md={3} lg={3} xl={true}>
                    <a
                      className={sponsor7.link ? "" : "disable-link"}
                      href={sponsor7.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor7.src} alt={sponsor7.altTxt} />
                    </a>
                  </Col>
                  <Col xs={3} md={3} lg={3} xl={true}>
                    <a
                      className={sponsor8.link ? "" : "disable-link"}
                      href={sponsor8.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor8.src} alt={sponsor8.altTxt} />
                    </a>
                  </Col>
                </Row>
                <Row
                  className="mt-xl-4"
                  noGutters
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Col xs={3} md={3} lg={3} xl={true}>
                    <a
                      className={sponsor9.link ? "" : "disable-link"}
                      href={sponsor9.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor9.src} alt={sponsor9.altTxt} />
                    </a>
                  </Col>
                  <Col xs={3} md={3} lg={3} xl={true}>
                    <a
                      className={sponsor10.link ? "" : "disable-link"}
                      href={sponsor10.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor10.src} alt={sponsor10.altTxt} />
                    </a>
                  </Col>
                  <Col xs={3} md={3} lg={3} xl={true}>
                    <a
                      className={sponsor11.link ? "" : "disable-link"}
                      href={sponsor11.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor11.src} alt={sponsor11.altTxt} />
                    </a>
                  </Col>
                  <Col xs={3} md={3} lg={3} xl={true}>
                    <a
                      className={sponsor12.link ? "" : "disable-link"}
                      href={sponsor12.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor12.src} alt={sponsor12.altTxt} />
                    </a>
                  </Col>
                  <Col xs={3} md={3} lg={3} xl={true}>
                    <a
                      className={sponsor13.link ? "" : "disable-link"}
                      href={sponsor13.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor13.src} alt={sponsor13.altTxt} />
                    </a>
                  </Col>
                  <Col xs={3} md={3} lg={3} xl={true}>
                    <a
                      className={sponsor14.link ? "" : "disable-link"}
                      href={sponsor14.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor14.src} alt={sponsor14.altTxt} />
                    </a>
                  </Col>
                  <Col xs={3} md={3} lg={3} xl={true}>
                    <a
                      className={sponsor15.link ? "" : "disable-link"}
                      href={sponsor15.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor15.src} alt={sponsor15.altTxt} />
                    </a>
                  </Col>
                  <Col xs={3} md={3} lg={3} xl={true}>
                    <a
                      className={sponsor16.link ? "" : "disable-link"}
                      href={sponsor16.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor16.src} alt={sponsor16.altTxt} />
                    </a>
                  </Col>
                </Row>
                <Row
                  className="mt-xl-4"
                  noGutters
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Col xs={3} md={3} lg={3} xl={true}>
                    <a
                      className={sponsor17.link ? "" : "disable-link"}
                      href={sponsor17.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor17.src} alt={sponsor17.altTxt} />
                    </a>
                  </Col>
                  <Col xs={3} md={3} lg={3} xl={true}>
                    <a
                      className={sponsor18.link ? "" : "disable-link"}
                      href={sponsor18.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor18.src} alt={sponsor18.altTxt} />
                    </a>
                  </Col>
                  <Col xs={3} md={3} lg={3} xl={true}>
                    <a
                      className={sponsor19.link ? "" : "disable-link"}
                      href={sponsor19.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor19.src} alt={sponsor19.altTxt} />
                    </a>
                  </Col>
                  <Col xs={3} md={3} lg={3} xl={true}>
                    <a
                      className={sponsor20.link ? "" : "disable-link"}
                      href={sponsor20.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor20.src} alt={sponsor20.altTxt} />
                    </a>
                  </Col>
                  <Col xs={3} md={3} lg={3} xl={true}>
                    <a
                      className={sponsor21.link ? "" : "disable-link"}
                      href={sponsor21.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor21.src} alt={sponsor21.altTxt} />
                    </a>
                  </Col>
                  <Col xs={3} md={3} lg={3} xl={true}>
                    <a
                      className={sponsor22.link ? "" : "disable-link"}
                      href={sponsor22.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor22.src} alt={sponsor22.altTxt} />
                    </a>
                  </Col>
                  <Col xs={3} md={3} lg={3} xl={true}>
                    <a
                      className={sponsor23.link ? "" : "disable-link"}
                      href={sponsor23.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor23.src} alt={sponsor23.altTxt} />
                    </a>
                  </Col>
                  <Col xs={3} md={3} lg={3} xl={true}>
                    <a
                      className={sponsor24.link ? "" : "disable-link"}
                      href={sponsor24.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor24.src} alt={sponsor24.altTxt} />
                    </a>
                  </Col>
                </Row>
                <Row
                  className="mt-xl-4"
                  noGutters
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Col xs={3} md={3} lg={3} xl={true}>
                    <a
                      className={sponsor25.link ? "" : "disable-link"}
                      href={sponsor25.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor25.src} alt={sponsor25.altTxt} />
                    </a>
                  </Col>
                  <Col xs={3} md={3} lg={3} xl={true}>
                    <a
                      className={sponsor26.link ? "" : "disable-link"}
                      href={sponsor26.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor26.src} alt={sponsor26.altTxt} />
                    </a>
                  </Col>
                  <Col xs={3} md={3} lg={3} xl={true}>
                    <a
                      className={sponsor27.link ? "" : "disable-link"}
                      href={sponsor27.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor27.src} alt={sponsor27.altTxt} />
                    </a>
                  </Col>
                  <Col xs={3} md={3} lg={3} xl={true}>
                    <a
                      className={sponsor28.link ? "" : "disable-link"}
                      href={sponsor28.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor28.src} alt={sponsor28.altTxt} />
                    </a>
                  </Col>
                  <Col xs={3} md={3} lg={3} xl={true}>
                    <a
                      className={sponsor29.link ? "" : "disable-link"}
                      href={sponsor29.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor29.src} alt={sponsor29.altTxt} />
                    </a>
                  </Col>
                  <Col xs={3} md={3} lg={3} xl={true}>
                    <a
                      className={sponsor30.link ? "" : "disable-link"}
                      href={sponsor30.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor30.src} alt={sponsor30.altTxt} />
                    </a>
                  </Col>
                  <Col xs={3} md={3} lg={3} xl={true}>
                    <a
                      className={sponsor31.link ? "" : "disable-link"}
                      href={sponsor31.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor31.src} alt={sponsor31.altTxt} />
                    </a>
                  </Col>
                  <Col xs={3} md={3} lg={3} xl={true}>
                    <a
                      className={sponsor32.link ? "" : "disable-link"}
                      href={sponsor32.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor32.src} alt={sponsor32.altTxt} />
                    </a>
                  </Col>
                </Row>
                <Row
                  className="mt-xl-4"
                  noGutters
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Col xs={3} md={3} lg={3} xl={true}>
                    <a
                      className={sponsor33.link ? "" : "disable-link"}
                      href={sponsor33.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor33.src} alt={sponsor33.altTxt} />
                    </a>
                  </Col>
                  <Col xs={3} md={3} lg={3} xl={true}>
                    <a
                      className={sponsor34.link ? "" : "disable-link"}
                      href={sponsor34.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor34.src} alt={sponsor34.altTxt} />
                    </a>
                  </Col>
                  <Col xs={3} md={3} lg={3} xl={true}>
                    <a
                      className={sponsor35.link ? "" : "disable-link"}
                      href={sponsor35.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor35.src} alt={sponsor35.altTxt} />
                    </a>
                  </Col>
                  <Col xs={0} md={0} lg={3} xl={true}></Col>
                  <Col xs={0} md={0} lg={3} xl={true}></Col>
                  <Col xs={0} md={0} lg={3} xl={true}></Col>
                  <Col xs={0} md={0} lg={3} xl={true}></Col>
                </Row>
                {/* <Row
                  className="mt-xl-4"
                  noGutters
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Col xs={3} md={3} lg={3} xl={true}>
                    <a
                      className={sponsor27.link ? "" : "disable-link"}
                      href={sponsor27.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor27.src} alt={sponsor27.altTxt} />
                    </a>
                  </Col>
                  <Col xs={3} md={3} lg={3} xl={true}>
                    <a
                      className={sponsor28.link ? "" : "disable-link"}
                      href={sponsor28.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor28.src} alt={sponsor28.altTxt} />
                    </a>
                  </Col>
                  <Col xs={3} md={3} lg={3} xl={true}>
                    <a
                      className={sponsor29.link ? "" : "disable-link"}
                      href={sponsor29.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor29.src} alt={sponsor29.altTxt} />
                    </a>
                  </Col>
                  <Col xs={3} md={3} lg={3} xl={true}>
                    <a
                      className={sponsor30.link ? "" : "disable-link"}
                      href={sponsor30.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor30.src} alt={sponsor30.altTxt} />
                    </a>
                  </Col>
                  <Col xs={3} md={3} lg={3} xl={true}>
                    <a
                      className={sponsor31.link ? "" : "disable-link"}
                      href={sponsor31.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor31.src} alt={sponsor31.altTxt} />
                    </a>
                  </Col>
                  <Col xs={3} md={3} lg={3} xl={true}>
                    <a
                      className={sponsor32.link ? "" : "disable-link"}
                      href={sponsor32.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor32.src} alt={sponsor32.altTxt} />
                    </a>
                  </Col>
                </Row> */}
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default SponsorBanner;
