import React, { useState } from "react";
import { slide as Menu } from "react-burger-menu";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import "./burgerMenu.styles.scss";

function BurgerMenu(props) {
  const [menuOpen, setMenuOpen] = useState(false);
  // This keeps your state in sync with the opening/closing of the menu
  // via the default means, e.g. clicking the X, pressing the ESC key etc.
  const handleStateChange = (state) => {
    setMenuOpen(state.isOpen);
  };

  // This can be used to close the menu, e.g. when a user clicks a menu item
  const closeMenu = () => {
    setMenuOpen(false);
  };
  const openMenu = () => {
    setMenuOpen(true);
  };
  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -10;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };
  return (
    <div
      className={`menu-container menu-mobile ${
        props.isInfoHub ? "info-hub-burger" : ""
      }`}
    >
      <div
        aria-label="Menu"
        className="menu-bg"
        onClick={() => {
          openMenu();
        }}
        onKeyDown={() => {
          openMenu();
        }}
        role="button"
        tabIndex="0"
      />
      <div className="bg-container">
        <Menu
          {...props}
          isOpen={menuOpen}
          onStateChange={(state) => handleStateChange(state)}
          width={"350px"}
        >
          <HashLink
            smooth
            className="link-nav button btn menu-item"
            scroll={(el) => scrollWithOffset(el)}
            to={"/#senate-2"}
            onClick={() => {
              closeMenu();
            }}
          >
            {"U.S. SENATE #2"}
          </HashLink>
          <HashLink
            smooth
            className="link-nav button btn menu-item"
            scroll={(el) => scrollWithOffset(el)}
            to={"/#senate-1"}
            onClick={() => {
              closeMenu();
            }}
          >
            {"U.S. SENATE #1"}
          </HashLink>
          <HashLink
            smooth
            className="link-nav button btn menu-item"
            scroll={(el) => scrollWithOffset(el)}
            to={"/#runoff-info"}
            onClick={() => {
              closeMenu();
            }}
          >
            {"RUNOFF INFORMATION"}
          </HashLink>
          <HashLink
            smooth
            className="link-nav button btn menu-item"
            scroll={(el) => scrollWithOffset(el)}
            to={"/#about-us"}
            onClick={() => {
              closeMenu();
            }}
          >
            {"WHO WE ARE"}
          </HashLink>
          <Link
            className="link-nav button btn info-link lang-button"
            to={"/voting-resources"}
            onClick={() => {
              closeMenu();
            }}
          >
            {"VOTER INFORMATION"}
          </Link>
        </Menu>
        {props.isMobile ? null : (
          <span
            onClick={() => {
              openMenu();
            }}
            onKeyDown={() => {
              openMenu();
            }}
            className="menu-text"
            role="button"
            tabIndex="0"
          >
            MENU
          </span>
        )}
      </div>
    </div>
  );
}

export default BurgerMenu;
