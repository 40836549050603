import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Calendar, LineSplit } from "../../components";
import "./RegisterToVote.style.scss";
import { Trans, useTranslation } from "react-i18next";

function RegisterToVote() {
  const { t } = useTranslation();
  return (
    <div className="ctn-register-tovote">
      <div className="register-to-vote">
        <div className="desciption">{t("not_sure")}</div>
        <a href="/#voter-status">
          <button type="button" className="btn-register">
            {t("check_your_registration_status")}
          </button>
        </a>
      </div>
      <LineSplit isGreen="true" />
      <Calendar />
    </div>
  );
}
export default RegisterToVote;
